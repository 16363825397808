
<template>
	<component :is="'div'">
		<!-- Alert: No item found -->
		<b-alert
			variant="danger"
			:show="competitionData === undefined"
		>
			<h4 class="alert-heading">Error al obtener los datos de la licitación</h4>
			<div class="alert-body">
				No se encontró ninguna licitación. Verificar en la
				<b-link
					class="alert-link"
					:to="{ name: 'competitions-list'}"
				>Lista de licitaciones</b-link>para buscar otra licitación.
			</div>
		</b-alert>

		<b-tabs
			v-model="activeTab"
			v-if="competitionData"
			pills
		>
			<!-- Tab: General Information -->
			<b-tab name="information">
				<template #title>
					<feather-icon
						icon="InfoIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Información general</span>
				</template>

				<tab-informations
					class="mt-2 pt-75"
					:competition-id="competitionData.id"
					:competition-data="competitionData"
					:tender-data="competitionData.tender"
				/>
			</b-tab>

			<b-tab name="schedule">
				<template #title>
					<feather-icon
						icon="CalendarIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Cronograma</span>
				</template>

				<tab-schedules
					class="mt-2 pt-75"
					:schedule-data="competitionData.tender.tender_schedules"
				/>
			</b-tab>

			<b-tab
				name="visit"
				v-if="competitionData.tender.request.request_information.enabled_visit"
			>
				<template #title>
					<feather-icon
						icon="MapPinIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Visitas</span>
				</template>

				<tab-visits
					class="mt-2 pt-75"
					:competition-id="competitionData.id"
					:tender-id="competitionData.tender.id"
					:competition-data="competitionData"
					:schedule-data="competitionData.tender.tender_schedules"
				/>
			</b-tab>

			<!-- Tab: Participant -->
			<b-tab name="consultation">
				<template #title>
					<feather-icon
						icon="InboxIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Consultas</span>
				</template>

				<tab-consultations
					class="mt-2 pt-75"
					:competition-id="competitionData.id"
					:tender-id="competitionData.tender.id"
					:competition-data="competitionData"
					:schedule-data="competitionData.tender.tender_schedules"
				/>
			</b-tab>

			<!-- Tab: Proposal -->
			<b-tab name="proposal">
				<template #title>
					<feather-icon
						icon="InboxIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Propuestas</span>
				</template>

				<tab-proposals
					class="mt-2 pt-75"
					:competition-data="competitionData"
					:schedule-data="competitionData.tender.tender_schedules"
				/>
			</b-tab>
		</b-tabs>
	</component>
</template>

<script>
import { ref, onUnmounted } from "@vue/composition-api"
import TabInformations from "./tabs/tab-informations/TabInformations.vue"
import TabSchedules from "./tabs/tab-schedules/TabSchedules.vue"
import TabVisits from "./tabs/tab-visits/TabVisitsList.vue"
import TabProposals from "./tabs/tab-proposals/TabProposalsEdit.vue"
import TabConsultations from "./tabs/tab-consultations/TabConsultationsList.vue"
import competitionStoreModule from "../competitionStoreModule"
import useNotifications from "@notifications"
import router from "@/router"
import store from "@/store"

export default {
	components: {
		TabInformations,
		TabSchedules,
		TabVisits,
		TabProposals,
		TabConsultations
	},
	computed: {
		activeTab: {
			set(val) {
				let query = { ...router.currentRoute.query }
				query.tab = val
				router.replace({ query: query })
			},
			get() {
				return parseInt(router.currentRoute.query.tab)
			}
		}
	},
	setup() {
		const { toastNotification } = useNotifications()
		// REFS
		const competitionData = ref(null)

		const COMPETITION_APP_STORE_MODULE_NAME = "app-competition"

		// REGISTER MODULE
		if (!store.hasModule(COMPETITION_APP_STORE_MODULE_NAME))
			store.registerModule(
				COMPETITION_APP_STORE_MODULE_NAME,
				competitionStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(COMPETITION_APP_STORE_MODULE_NAME))
				store.unregisterModule(COMPETITION_APP_STORE_MODULE_NAME)
		})

		store
			.dispatch("app-competition/fetchCompetition", {
				id: router.currentRoute.params.id
			})
			.then((response) => {
				competitionData.value = response.data.competition
			})
			.catch((error) => {
				toastNotification(error.response.data.message, false)
				if (error.response.status === 404) {
					competitionData.value = undefined
				}
				/* router.push({ name: "competitions-list" }) */
			})

		return {
			// DATA
			competitionData
		}
	}
}
</script>