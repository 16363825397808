<template>
	<section>
		<b-card-countdown
			stage="presentación de consultas"
			:deadline="deadline"
			:start-date="startDate"
			:stage-closed="stageClosed"
			@time-finished="handleTimeFinished"
			@not-yet-started="handleNotYetStarted"
		/>

		<b-card
			no-body
			class="mb-0"
		>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
					>
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>

					<!-- Search -->
					<b-col
						cols="12"
						md="6"
						class="ml-auto"
					>
						<div class="d-flex">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar ..."
								trim
							/>
							<b-button
								v-if="!isDisabled"
								variant="primary"
								@click="openConsultationModal(true)"
								:disabled="isDisabled"
							>
								<span class="text-nowrap">NUEVA CONSULTA</span>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>

			<b-table
				ref="refConsultationListTable"
				class="position-relative table-bidders"
				:style="totalConsultations >= 1 && totalConsultations <= 3 ? 'height: 280px' : 'height: inherit'"
				:items="fetchConsultations"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #table-busy>
					<div class="text-center text-primary my-2">
						<b-spinner class="align-middle mb-1"></b-spinner>
						<div>
							<b>CARGANDO</b>
						</div>
					</div>
				</template>

				<template #head(code)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(createdAt)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(absolved_at)="data">
					<span v-html="data.label"></span>
				</template>

				<template #cell(code)="data">
					<div class="text-nowrap">{{ data.item.code }}</div>
				</template>

				<template #cell(bidder_name)="data">
					<div class="text-nowrap">{{ data.item.bidder_name }}</div>
				</template>

				<template #cell(question)="data">
					<div
						class="question-nowrap"
					>{{ data.item.question ? data.item.question.slice(0, 50).concat('...') : '' }}</div>
				</template>

				<template #cell(status)="data">
					<div class="text-nowrap">
						<b-badge
							:variant="`light-${data.item.status === true ? 'success' : data.item.status === false ? 'danger' : 'warning' }`"
						>{{ data.item.status === true ? 'ABSUELTA' : data.item.status === false ? 'SIN ABSOLVER' : 'PENDIENTE' }}</b-badge>
					</div>
				</template>

				<template #cell(documents)="data">
					<div class="text-nowrap">
						<b-button-upload
							entity="COMPETITIONS"
							:entity-id="competitionId"
							:is-disabled="isDisabled || data.item.status"
							:competition-props="{ isConsultation: true, ownerId: data.item.id }"
							:modal-props="{ title: 'DOCUMENTOS DE CONSULTA' }"
							:button-props="{  onlyIcon: true, size: 'sm' }"
						/>
					</div>
				</template>

				<template #cell(createdAt)="data">
					<div
						class="text-nowrap"
					>{{ data.item.createdAt ? moment(data.item.createdAt).format('DD-MM-YYYY HH:mm') : '-' }}</div>
				</template>

				<template #cell(absolved_at)="data">
					<div
						class="text-nowrap"
					>{{ data.item.absolved_at ? moment(data.item.absolved_at).format('DD-MM-YYYY HH:mm') : '-' }}</div>
				</template>

				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL"
					>
						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>

						<b-dropdown-item @click="openConsultationModal(false, data.item)">
							<feather-icon icon="EyeIcon" />
							<span class="align-middle ml-50">Ver</span>
						</b-dropdown-item>

						<b-dropdown-item
							v-if="validateOwner(data.item.competition_id) && !data.item.status && !isDisabled"
							@click="openConsultationModal(false, data.item, validateOwner(data.item.competition_id))"
						>
							<feather-icon icon="EditIcon" />
							<span class="align-middle ml-50">Editar</span>
						</b-dropdown-item>

						<b-dropdown-item
							v-if="validateOwner(data.item.competition_id) && !data.item.status && !isDisabled"
							@click="deleteConsultation(data.item.id)"
						>
							<feather-icon icon="TrashIcon" />
							<span class="align-middle ml-50">Eliminar</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</b-table>

			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span
							class="text-muted"
						>Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalConsultations"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>

		<b-modal
			ref="refConsultationModal"
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			cancel-title="Cerrar"
			centered
			title="CONSULTA SOBRE LA LICITACIÓN"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
			hide-footer
			size="lg"
		>
			<validation-observer
				#default="{ handleSubmit }"
				ref="refConsultationForm"
			>
				<b-form @submit.prevent="handleSubmit(saveConsultationModal)">
					<b-card
						footer-tag="footer"
						class="mb-0"
					>
						<b-row>
							<b-col
								cols="12"
								class="mb-2"
							>
								<label for="description">
									<strong>Consulta</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="Consulta"
								>
									<b-form-textarea
										id="description"
										v-model="formData.question"
										:state="errors.length > 0 ? false : null"
										placeholder="Ingrese consulta ..."
										:disabled="formData.readonly"
										:readonly="formData.readonly"
										rows="5"
										trim
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<b-col
								cols="12"
								class="mb-2"
								v-if="formData.status === true"
							>
								<label for="description">
									<strong>Consulta Absueta</strong>
								</label>
								<b-form-textarea
									id="description"
									:value="formData.absolution"
									rows="5"
									disabled
									readonly
								/>
							</b-col>

							<b-col
								cols="12"
								class="mb-2"
								v-if="formData.status === true"
							>
								<label>
									<strong>Documentos de Absolución</strong>
								</label>
								<b-button-upload
									entity="COMPETITIONS"
									:entity-id="competitionId"
									:is-disabled="true"
									:competition-props="{ isAbsolution: true, ownerId: formData.id }"
									:modal-props="{ title: 'DOCUMENTOS DE ABSOLUCIÓN' }"
									:button-props="{ text: 'VER DOCUMENTOS', block : true }"
								/>
							</b-col>
						</b-row>

						<template #footer>
							<b-col class="d-flex flex-column flex-sm-row justify-content-sm-end">
								<!-- BUTTON SUBMIT -->
								<b-button-loading
									v-if="!formData.readonly"
									text="GUARDAR"
									type="submit"
									class="order-sm-2 order-1 ml-sm-2 ml-0"
									:processing="processing"
									:disabled="isDisabled"
								/>

								<b-button
									v-if="!processing"
									v-ripple.400="'rgba(186, 191, 199, 0.15)'"
									type="button"
									variant="outline-secondary"
									class="ml-2"
									@click="closeConsultationModal"
								>CERRAR</b-button>
							</b-col>
						</template>
					</b-card>
				</b-form>
			</validation-observer>
		</b-modal>
	</section>
</template>

<script>
import {
	ref,
	onBeforeMount,
	onUnmounted,
	onMounted
} from "@vue/composition-api"
import { Stages, CompetitionStates } from "@core/utils/data"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { required } from "@validations"
import BButtonUploadFile from "@core/components/b-button-upload-file/BButtonUploadFile.vue"
import BButtonUpload from "@core/components/b-button-upload/BButtonUpload.vue"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import BCardCountdown from "@core/components/b-card-countdown/BCardCountdown"
import consultationStoreModule from "./consultationStoreModule"
import useTabConsultationsList from "./useTabConsultationsList"
import VueSweetalert2 from "vue-sweetalert2"
import useNotifications from "@notifications"
import vSelect from "vue-select"
import store from "@/store"
import moment from "moment"
import axios from "@axios"
import Ripple from "vue-ripple-directive"
import Vue from "vue"
import "animate.css"

Vue.use(VueSweetalert2)

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
		BCardCountdown,
		BButtonUploadFile,
		BButtonUpload,
		BButtonLoading,

		required,
		vSelect
	},
	directives: {
		Ripple
	},
	data() {
		return {
			moment
		}
	},
	props: {
		tenderId: {
			type: Number,
			required: true
		},
		competitionId: {
			type: Number,
			required: true
		},
		scheduleData: {
			type: Array,
			required: true
		},
		competitionData: {
			type: Object,
			required: true
		}
	},
	setup(props) {
		// USE TOAST
		const { swalNotification } = useNotifications()
		const { FINALIZADO, EN_CURSO } = CompetitionStates

		// REFS
		const tenderId = ref(props.tenderId)
		const competitionId = ref(props.competitionId)
		const competitionData = ref(props.competitionData)
		const scheduleData = ref(props.scheduleData)
		const deadline = ref(null)
		const startDate = ref(null)
		const isDisabled = ref(false)
		const stageClosed = ref(false)
		const isUpdating = ref(false)
		const processing = ref(false)

		const refConsultationModal = ref(null)
		const formData = ref({
			id: null,
			question: null,
			absolution: null,
			competition_id: null,
			status: null,
			readonly: false
		})

		if (competitionData.value.competition_state_id !== EN_CURSO) {
			isDisabled.value = true
			stageClosed.value = true
		}

		const BIDDER_APP_STORE_MODULE_NAME = "app-consultation"

		// REGISTER MODULE
		if (!store.hasModule(BIDDER_APP_STORE_MODULE_NAME))
			store.registerModule(
				BIDDER_APP_STORE_MODULE_NAME,
				consultationStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(BIDDER_APP_STORE_MODULE_NAME))
				store.unregisterModule(BIDDER_APP_STORE_MODULE_NAME)
		})

		// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
		onBeforeMount(() => {
			setData()
		})

		const setData = () => {
			const consultation = scheduleData.value.find(
				(item) => parseInt(item.stage_id) === Stages.CONSULTA
			)
			startDate.value = consultation.actual_start_date || ""
			deadline.value = consultation.actual_end_date || ""
		}

		const validateOwner = (id) => {
			let is_owner = false
			if (competitionId.value === id) {
				is_owner = true
			}
			return is_owner
		}

		const deleteConsultation = (id) => {
			Vue.swal({
				title: "¿Está seguro(a) de eliminar la consulta?",
				html: "<small>¡Si no lo está, puede cancelar la acción!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, eliminar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			}).then((result) => {
				if (result.value) {
					store
						.dispatch("app-consultation/deleteConsultation", {
							id,
							queryParams: {
								tender_id: tenderId.value,
								competition_id: competitionId.value
							}
						})
						.then((response) => {
							refetchData()
							swalNotification(response.data.message)
						})
						.catch((error) => {
							const message =
								error.response.data.message || "Error al eliminar la consulta."
							swalNotification(message, false)
						})
				}
			})
		}

		const handleTimeFinished = () => {
			stageClosed.value = true
			isDisabled.value = true
		}
		const handleNotYetStarted = (value) => {
			if (competitionData.value.competition_state_id !== EN_CURSO) {
				stageClosed.value = true
				isDisabled.value = true
			} else {
				isDisabled.value = value
				stageClosed.value = value
			}
		}

		const saveConsultationModal = () => {
			process.value = true

			let action = "createConsultation"
			let params = {
				payload: {
					tender_id: tenderId.value,
					competition_id: competitionId.value,
					question: formData.value.question
				}
			}
			if (isUpdating.value) {
				action = "updateConsultation"
				params.id = formData.value.id
			}

			store
				.dispatch(`app-consultation/${action}`, params)
				.then((response) => {
					refetchData()
					swalNotification(response.data.message)
					closeConsultationModal()
				})
				.catch((error) => {
					resetModal()
					const message =
						error.response.data.message ||
						`Error al ${
							isUpdating.value ? "actualizar" : "agregar"
						} la consulta.`
					swalNotification(message, false)
				})
				.finally(() => {
					process.value = false
				})
		}

		const openConsultationModal = (isNew, data, isOwner) => {
			if (!isNew && data) {
				formData.value = {
					id: data.id,
					competition_id: data.competition_id,
					question: data.question,
					absolution: data.absolution,
					status: data.status,
					readonly: !isOwner || data.status === 1
				}
				isUpdating.value = isOwner && !data.status
			}

			refConsultationModal.value.show()
		}

		const closeConsultationModal = () => {
			resetModal()
			refConsultationModal.value.hide()
		}

		const resetModal = () => {
			isUpdating.value = false
			formData.value = {
				id: null,
				question: null,
				absolution: null,
				status: null
			}
		}

		const {
			fetchConsultations,
			tableColumns,
			perPage,
			currentPage,
			totalConsultations,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refConsultationListTable,
			refetchData
		} = useTabConsultationsList(competitionId.value, tenderId.value)

		return {
			fetchConsultations,
			tableColumns,
			perPage,
			currentPage,
			totalConsultations,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refConsultationListTable,
			refetchData,
			deadline,
			startDate,
			stageClosed,
			isUpdating,
			isDisabled,
			processing,

			refConsultationModal,
			formData,
			validateOwner,
			Stages,

			// ACTIONS
			deleteConsultation,
			handleTimeFinished,
			handleNotYetStarted,
			saveConsultationModal,
			openConsultationModal,
			closeConsultationModal
		}
	}
}
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>