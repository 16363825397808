<template>
	<section>
		<b-card-countdown
			stage="recepción de propuestas"
			:deadline="deadline"
			:start-date="startDate"
			:stage-closed="stageClosed"
			@time-finished="handleTimeFinished"
			@not-yet-started="handleNotYetStarted"
		/>

		<b-row>
			<b-col cols="12">
				<b-card>
					<b-tabs
						content-class="mt-2"
						justified
						v-if="competitionData"
					>
						<b-tab
							active
							title="Propuesta Económica"
						>
							<tab-proposal-economic
								:tender-id="competitionData.tender.id"
								:competition-id="competitionData.id"
								:economic-proposal-tender="competitionData.tender.request.request_economic_template"
								:economic-proposal="competitionData.competition_economic_proposal"
								:is-disabled="isDisabled"
							/>
						</b-tab>

						<b-tab title="Propuesta de Seguridad, Calidad, Medio Ambiente y Anti Corrupción">
							<tab-proposal-sig
								:tender-id="competitionData.tender.id"
								:competition-id="competitionData.id"
								:proposals-data="competitionData.competition_sig_technical_proposals"
								:is-disabled="isDisabled"
							/>
						</b-tab>

						<b-tab title="Propuesta Técnica">
							<tab-proposal-technical
								:tender-id="competitionData.tender.id"
								:competition-id="competitionData.id"
								:proposals-data="competitionData.competition_sig_technical_proposals"
								:is-disabled="isDisabled"
							/>
						</b-tab>
					</b-tabs>
				</b-card>
			</b-col>

			<b-col cols="12">
				<b-button-loading
					v-if="!isDisabled"
					text="ENVIAR PROPUESTAS"
					variant="relief-primary"
					size="lg"
					:block="true"
					@process-action="sendInformation"
					:processing="processing"
					:disabled="isDisabled"
				/>
			</b-col>
		</b-row>
	</section>
</template>

<script>
import { ref } from "@vue/composition-api"
import { CompetitionStates, Stages } from "@core/utils/data"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import BCardCountdown from "@core/components/b-card-countdown/BCardCountdown"
import TabProposalEconomic from "./proposal-economic/TabProposalEconomic.vue"
import TabProposalSig from "./proposal-sig/TabProposalSig.vue"
import TabProposalTechnical from "./proposal-technical/TabProposalTechnical.vue"
import useNotifications from "@notifications"
import VueSweetalert2 from "vue-sweetalert2"
import router from "@/router"
import axios from "@axios"
import Vue from "vue"

Vue.use(VueSweetalert2)

export default {
	components: {
		BCardCountdown,
		BButtonLoading,
		TabProposalEconomic,
		TabProposalSig,
		TabProposalTechnical
	},
	props: {
		competitionData: {
			type: Object,
			default: () => {},
			required: true
		},
		scheduleData: {
			type: Array,
			required: true
		}
	},
	setup(props) {
		const { swalNotification, toastNotification } = useNotifications()
		const { FINALIZADO, EN_CURSO } = CompetitionStates

		const scheduleData = ref(props.scheduleData || [])
		const competitionData = ref(props.competitionData)
		const competitionId = ref(props.competitionData.id)
		const tenderId = ref(props.competitionData.tender.id)
		const deadline = ref(null)
		const startDate = ref(null)
		const processing = ref(false)
		const isDisabled = ref(false)
		const stageClosed = ref(false)
		const timeLeft = ref({
			tenMinutes: false,
			fiveMinutes: false,
			threeMinute: false
		})

		if (competitionData.value.competition_state_id !== EN_CURSO) {
			stageClosed.value = true
			isDisabled.value = true
		}

		const proposal = scheduleData.value.find(
			(item) => parseInt(item.stage_id) === Stages.RECEPCION_PROPUESTA
		)
		startDate.value = proposal.actual_start_date || ""
		deadline.value = proposal.actual_end_date || ""

		const handleTimeFinished = () => {
			stageClosed.value = true
			isDisabled.value = true
		}

		const handleNotYetStarted = (value) => {
			if (competitionData.value.competition_state_id !== EN_CURSO) {
				stageClosed.value = true
				isDisabled.value = true
			} else {
				isDisabled.value = value
				stageClosed.value = value
			}
		}

		/* if (!isDisabled.value && state.value === CompetitionStates.EN_CURSO) {
			swalNotificationCloser({ goTo: "competitions-list" })
		}

		if (state.value === CompetitionStates.EN_CURSO) {
			swalNotificationTimer({ ...timeLeft.value })
		} */
		const sendInformation = async () => {
			Vue.swal({
				title: "¿Está seguro(a) de enviar las propuestas?",
				html: "<small>¡Recuerda <strong>guardar tus cambios</strong> antes de enviar!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, enviar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			}).then(async (result) => {
				if (!result.value) return

				Vue.swal.fire({
					title: "Procesando...",
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
						Vue.swal.showLoading()
					}
				})

				processing.value = true

				try {
					const payload = {
						tender_id: tenderId.value,
						fromParams: true
					}
					const response = await axios.put(
						`/competitions/${competitionId.value}/proposals`,
						payload
					)
					/* swalNotification(response.data.message) */
					toastNotification(response.data.message)
					router.push({ name: "competitions-list" })
				} catch (error) {
					toastNotification(
						error.response.data.message ||
							"Ocurrió un error al enviar las propuestas",
						false
					)
					/* swalNotification(message, false) */
					/* Vue.swal.fire({
						icon: "error",
						title: "Error",
						timer: 4000,
						text:
							error.response.data.message ||
							"Ocurrió un error al enviar las propuestas",
						showConfirmButton: false,
						showCancelButton: false
					}) */
				} finally {
					Vue.swal.close()
					processing.value = false
				}
			})
		}

		return {
			deadline,
			startDate,
			processing,
			isDisabled,
			stageClosed,
			timeLeft,
			Stages,

			handleTimeFinished,
			handleNotYetStarted,
			sendInformation
		}
	}
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>