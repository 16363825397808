import axios from '@axios';

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchConsultations(ctx, { params }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/competition-consultations`, { params })
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		createConsultation(ctx, { payload}) {
			return new Promise((resolve, reject) => {
				axios
					.post('/competition-consultations/', payload)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		updateConsultation(ctx, { id, payload }) {
			return new Promise((resolve, reject) => {
				axios
					.put(`/competition-consultations/${id}`, payload)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		/* deleteConsultation(ctx, { id, payload }) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`/consultations/${id}?competition_id=${payload.competition_id}`)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		}, */
		deleteConsultation(ctx, { id, queryParams }) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`/competition-consultations/${id}`, { params: queryParams })
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
	}
}