<template>
	<section>
		<validation-observer
			#default="{ handleSubmit }"
			ref="refEconomicForm"
		>
			<b-form @submit.prevent="handleSubmit(saveInformation)">
				<b-card
					footer-tag="footer"
					class="mb-0"
				>
					<b-row>
						<b-col cols="12">
							<h5 class="mb-1">
								<strong>Presentación de Propuesta Económica</strong>
							</h5>

							<div v-html="economicProposalTender.description"></div>
						</b-col>

						<b-col
							cols="12"
							class="d-flex justify-content-end"
						>
							<b-button
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								variant="outline-primary"
								class="btn-icon rounded-circle mr-1"
								@click="openCommentModal()"
							>
								<feather-icon icon="MessageSquareIcon" />
							</b-button>

							<b-button-upload
								entity="COMPETITIONS"
								:entity-id="competitionId"
								:is-disabled="isDisabled"
								:competition-props="{  isEconomic: true, ownerId: economicProposal.id }"
								:modal-props="{ title: 'DOCUMENTOS DE PROPUESTA ECONÓMICA' }"
								:button-props="{ onlyIcon: true }"
							/>
						</b-col>

						<b-col
							cols="12"
							class="my-2"
						>
							<hr />
						</b-col>

						<b-col
							cols="4"
							class="mb-1"
						>
							<label for="tipo">
								<strong>TIPO DE MONEDA</strong>
							</label>
							<b-form-input
								id="tipo"
								placeholder="Soles"
								:value="economicProposalTender.currency_name"
								disabled
								readonly
							/>
						</b-col>
						<b-col
							cols="12"
							md="4"
							class="mb-1"
						>
							<label for="general_expenses">
								<strong>GASTO GENERAL (GG %)</strong>
							</label>
							<validation-provider
								#default="{ errors }"
								rules="required|min:0"
								name="Gasto General"
							>
								<b-form-input
									id="general_expenses"
									v-model="economicForm.general_expenses"
									@input="calculateStatistics()"
									:state="errors.length > 0 ? false : null"
									type="number"
									step="any"
									placeholder="0.00"
									:disabled="isDisabled"
									:readonly="isDisabled"
									trim
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-col>
						<b-col
							cols="12"
							md="4"
							class="mb-1"
						>
							<label for="profit">
								<strong>UTILIDAD (UTI %)</strong>
							</label>
							<validation-provider
								#default="{ errors }"
								rules="required|min:0"
								name="Utilidad"
							>
								<b-form-input
									id="profit"
									v-model="economicForm.profit"
									@input="calculateStatistics()"
									:state="errors.length > 0 ? false : null"
									type="number"
									step="any"
									placeholder="0.00"
									:disabled="isDisabled"
									:readonly="isDisabled"
									trim
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-col>

						<b-col
							cols="12"
							class="my-1"
						>
							<b-alert
								variant="secondary"
								show
							>
								<h6 class="alert-heading">
									<feather-icon
										icon="InfoIcon"
										class="mr-50"
									/>Cálculo de costos
								</h6>
								<div class="alert-body">
									<ul class="mb-0 pl-1">
										<li class="small">
											Descargar
											<b-link
												class="text-primary"
												:href="economicProposalTender.file_bidder_url"
											>
												<strong>partidas presupuestarias</strong>
											</b-link>
										</li>
										<li class="small">
											Cargar el archivo de partidas con los costos para calcular el
											<strong>importe total</strong>
										</li>
										<li
											class="small"
										>Para actualizar registros del archivo, modifique el mismo y vuelva a cargar</li>
									</ul>
								</div>
							</b-alert>
						</b-col>

						<b-col
							cols="12"
							v-if="!isDisabled"
						>
							<label for="templateFile">
								<strong>CARGAR EXCEL DE PARTIDAS</strong>
							</label>
							<b-form-file
								v-model="file"
								placeholder="Seleccione un archivo o suéltelo aquí..."
								accept=".xlsx, .xls"
								drop-placeholder="Suelte archivo aquí ..."
								:disabled="isDisabled"
								:readonly="isDisabled"
							/>
						</b-col>

						<b-col
							cols="12"
							v-if="economicForm.file_url"
						>
							<b-icon-file
								:filename="economicForm.file_original_name"
								:file-url="economicForm.file_url"
							/>
						</b-col>

						<b-col cols="12">
							<b-card
								no-body
								class="card-statistics mb-0"
							>
								<b-card-body class="statistics-body">
									<b-row>
										<b-col
											v-for="item in statisticsItems"
											:key="item.icon"
											md="3"
											sm="6"
											class="mb-2 mb-md-0"
											:class="item.customClass"
										>
											<b-media no-body>
												<b-media-aside class="mr-2">
													<b-avatar
														size="48"
														:variant="item.color"
													>{{ item.sign }}</b-avatar>
												</b-media-aside>
												<b-media-body class="my-auto">
													<h4 class="font-weight-bolder mb-0">{{ formatCurrency(item.amount) }}</h4>
													<b-card-text class="font-small-3 mb-0">{{ item.subtitle }}</b-card-text>
												</b-media-body>
											</b-media>
										</b-col>
									</b-row>
								</b-card-body>
							</b-card>
						</b-col>

						<b-col cols="12">
							<b-button-loading
								v-if="!isDisabled"
								text="GUARDAR CAMBIOS"
								type="submit"
								variant="outline-primary"
								:block="true"
								:processing="processing"
								:disabled="isDisabled"
							/>
						</b-col>
					</b-row>
				</b-card>
			</b-form>
		</validation-observer>

		<b-modal
			ref="refCommentModal"
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			centered
			title="PRESENTACIÓN DE PROPUESTA ECONÓMICA"
			size="lg"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
		>
			<b-form>
				<b-col
					cols="12"
					class="my-2"
				>
					<label for="comment">
						<strong>Comentario</strong>
					</label>
					<b-form-group>
						<b-form-textarea
							id="comment"
							v-model="comment"
							placeholder="Ingrese texto..."
							rows="4"
							:disabled="isDisabled"
							:readonly="isDisabled"
							trim
						/>
					</b-form-group>
				</b-col>
			</b-form>

			<template #modal-footer="{ cancel }">
				<b-button
					v-if="!isDisabled"
					@click.prevent="saveCommentModal"
					variant="primary"
					:disabled="isDisabled"
				>ACEPTAR</b-button>

				<b-button
					v-ripple.400="'rgba(186, 191, 199, 0.15)'"
					type="button"
					variant="outline-secondary"
					class="ml-2"
					@click="cancel"
				>CERRAR</b-button>
			</template>
		</b-modal>
	</section>
</template>

<script>
import { ref, onBeforeMount, onMounted } from "@vue/composition-api"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { required, min } from "@validations"
import BCardCountdown from "@core/components/b-card-countdown/BCardCountdown"
import BButtonUpload from "@core/components/b-button-upload/BButtonUpload.vue"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import BIconFile from "@core/components/b-icon-file/BIconFile.vue"
import Ripple from "vue-ripple-directive"
import { VBModal } from "bootstrap-vue"
import axios from "@axios"
import useNotifications from "@notifications"
import { formatCurrency } from "@/@core/utils/filter"

export default {
	components: {
		ValidationProvider,
		ValidationObserver,

		BCardCountdown,
		BButtonLoading,
		BButtonUpload,
		BIconFile,

		required,
		min
	},
	directives: {
		"b-modal": VBModal,
		Ripple
	},
	props: {
		tenderId: {
			type: Number,
			required: true
		},
		competitionId: {
			type: Number,
			required: true
		},
		economicProposal: {
			type: Object,
			default: () => {},
			required: true
		},
		economicProposalTender: {
			type: Object,
			default: () => {},
			required: true
		},
		isDisabled: {
			type: Boolean,
			required: true
		}
	},
	setup(props) {
		const { toastNotification } = useNotifications()

		const tenderId = ref(props.tenderId)
		const competitionId = ref(props.competitionId)
		const economicProposal = ref(props.economicProposal)

		const file = ref(null)
		const refCommentModal = ref(null)
		const comment = ref(null)
		const processing = ref(false)

		const economicForm = ref({
			comment: null,
			subtotal: 0,
			general_expenses: 0,
			profit: 0,
			file_original_name: null,
			file_url: null
		})

		const statisticsItems = ref([
			{
				color: "light-primary",
				sign: "S/",
				amount: 0,
				subtitle: "COSTO DIRECTO",
				customClass: "mb-2 mb-xl-0"
			},
			{
				color: "light-info",
				amount: 0,
				sign: "S/",
				subtitle: "GASTO GENERAL",
				customClass: "mb-2 mb-xl-0"
			},
			{
				sign: "S/",
				color: "light-danger",
				amount: 0,
				subtitle: "UTILIDAD",
				customClass: "mb-2 mb-sm-0"
			},
			{
				sign: "S/",
				color: "light-success",
				amount: 0,
				subtitle: "IMPORTE TOTAL",
				customClass: ""
			}
		])

		const openCommentModal = () => {
			comment.value = economicForm.value.comment
			refCommentModal.value.show()
		}

		onMounted(() => {
			setData()
		})

		const setData = () => {
			const {
				general_expenses,
				profit,
				subtotal,
				file_original_name,
				file_url,
				comment
			} = economicProposal.value

			economicForm.value = {
				general_expenses: general_expenses
					? (parseFloat(general_expenses) * 100).toFixed(2)
					: 0,
				profit: profit ? (parseFloat(profit) * 100).toFixed(2) : 0,
				subtotal: subtotal ? parseFloat(subtotal) : 0,
				comment,
				file_original_name,
				file_url
			}

			calculateStatistics()
		}

		const saveCommentModal = async () => {
			try {
				const payload = {
					tender_id: tenderId.value,
					competition_id: competitionId.value,
					comment: comment.value
				}
				const response = await axios.put(
					`/competition-economic-proposals/${economicProposal.value.id}`,
					payload
				)

				toastNotification(response.data.message)
				economicForm.value.comment = comment.value
			} catch (error) {
				toastNotification(error.response.data.message, false)
			} finally {
				refCommentModal.value.hide()
			}
		}

		const saveInformation = async () => {
			processing.value = true
			try {
				const payload = {
					general_expenses: economicForm.value.general_expenses,
					profit: economicForm.value.profit
				}

				const sendFormData = new FormData()

				sendFormData.append("file", file.value)
				sendFormData.append("tender_id", tenderId.value)
				sendFormData.append("competition_id", competitionId.value)
				sendFormData.append("data", JSON.stringify(payload))

				const response = await axios.put(
					`/competition-economic-proposals/${economicProposal.value.id}/template`,
					sendFormData,
					{
						headers: { "Content-Type": "multipart/form-data" }
					}
				)
				const { subtotal, file_url, original_name } = response.data.economicData

				economicForm.value.file_url = file_url || economicForm.value.file_url
				economicForm.value.file_original_name = original_name
				economicForm.value.subtotal = subtotal

				/* const { subtotal, has_file, file_original_name, file_id, file_url } =
					response.data.economicData

				if (has_file) {
					economicForm.value.file = {
						id: file_id,
						filename: file_original_name,
						url: file_url
					}
					file.value = null
				}

				economicForm.value.profit = parseFloat(payload.profit).toFixed(2)
				economicForm.value.general_expenses = parseFloat(
					payload.general_expenses
				).toFixed(2)
				economicForm.value.subtotal = subtotal */

				calculateStatistics()

				toastNotification(response.data.message)
			} catch (error) {
				const message =
					error.response.data.message || "Error al actualizar la propuesta"
				toastNotification(message, false)
			} finally {
				processing.value = false
			}
		}

		const calculateStatistics = () => {
			statisticsItems.value[0].amount = economicForm.value.subtotal
			statisticsItems.value[1].amount =
				economicForm.value.subtotal *
				(economicForm.value.general_expenses / 100)
			statisticsItems.value[2].amount =
				economicForm.value.subtotal * (economicForm.value.profit / 100)

			statisticsItems.value[3].amount =
				statisticsItems.value[0].amount +
				statisticsItems.value[1].amount +
				statisticsItems.value[2].amount
		}

		return {
			refCommentModal,
			comment,
			file,
			statisticsItems,
			economicForm,
			processing,
			formatCurrency,

			openCommentModal,
			saveCommentModal,
			saveInformation,
			calculateStatistics
		}
	}
}
</script>