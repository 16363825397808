import axios from '@axios';

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchVisits(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				axios
					.get('/competition-visits/bidders', { params: queryParams })
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		createVisit(ctx, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post('/competition-visits', payload)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		/* deleteVisit(ctx, { id, payload }) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`/visits/${id}?tender_id=${payload.tender_id}&competition_id=${payload.competition_id}`)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		}, */
		deleteVisit(ctx, { id, queryParams }) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`/competition-visits/${id}`, { params: queryParams })
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
	}
}